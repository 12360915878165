import { faAngleDown } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cva } from 'class-variance-authority';
import { twMerge } from 'tailwind-merge';

import { CorporateTabItem } from '..';
import Image from '../../Image';

interface CorporateTabItemProps {
  item: CorporateTabItem;
  index: number;
  isActive: boolean;
  navigate: (index: number) => void;
  buttonClassName?: string;
  variant?: 'primary' | 'secondary';
  textClassName?: string;
}

const ItemStyling = cva(
  'w-full gap-2 md:gap-0 relative p-5 pr-10 md:p-2 lg:p-4 md:pr-4 transition-transform items-center flex',
  {
    variants: {
      active: {
        true: 'md:scale-110 xl:scale-105',
      },
      variant: {
        primary: 'md:col-span-1 aspect-[11/3] md:aspect-square md:flex-col md:justify-between',
        secondary: 'aspect-[11/3] md:flex-row md:justify-center',
      },
    },
  },
);

const TextStyling = cva('large flex items-end text-left', {
  variants: {
    variant: {
      primary: 'md:aspect-[2/1] md:w-full',
      secondary: '',
    },
  },
});

const ArrowStyling = cva('transition-transform flex md:hidden absolute right-4 text-white', {
  variants: {
    active: {
      true: 'rotate-180',
    },
  },
});

const CorporateTab: React.FC<CorporateTabItemProps> = ({
  item,
  index,
  isActive,
  navigate,
  buttonClassName,
  variant = 'primary',
  textClassName,
}) => {
  return (
    <button
      className={twMerge(ItemStyling({ active: isActive, variant }), buttonClassName)}
      onClick={() => navigate(index)}
      style={{ backgroundColor: item.color }}
    >
      {isActive && (
        <div
          className="absolute left-[50%] top-[100%] h-5 w-5 -translate-x-[50%] -translate-y-[50%] rotate-45 xl:h-7 xl:w-7"
          style={{ backgroundColor: item.color }}
        ></div>
      )}
      {variant === 'primary' && item.icon && (
        <div className={twMerge('flex max-w-[20%] items-center md:max-h-[50%] md:w-full md:max-w-full', textClassName)}>
          <Image
            alt={item.icon?.imageAlt ?? ''}
            className="h-auto w-auto object-contain md:h-full"
            src={item.icon?.imageUrl}
            wrapperClassName="w-auto h-auto md:h-full"
          />
        </div>
      )}
      <p className={twMerge(TextStyling({ variant }), textClassName)}>{item.title}</p>
      <div className={ArrowStyling({ active: isActive })}>
        <FontAwesomeIcon icon={faAngleDown} size="lg" />
      </div>
    </button>
  );
};

export default CorporateTab;
