import type { Nullable, SimpleImage } from '@boss/types/corporate-jobs';
import React, { ReactNode, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import CorporateTabsContentDesktop from './CorporateTabsContentDesktop';
import CorporateTabsDesktop from './CorporateTabsDesktop';
import CorporateTabsMobile from './CorporateTabsMobile';

export type CorporateTabItem = {
  title?: string;
  icon?: Nullable<SimpleImage>;
  color: string;
  content: ReactNode | string;
};

interface CorporateTabsProps {
  items: CorporateTabItem[];
  activeItem?: number;
  className?: string;
  contentClassName?: string;
  buttonClassName?: string;
  variant?: 'primary' | 'secondary';
}

const CorporateTabs: React.FC<CorporateTabsProps> = ({
  items,
  activeItem = 0,
  className,
  contentClassName,
  buttonClassName,
  variant = 'primary',
}) => {
  const [currentTab, setCurrentTab] = useState<number>(activeItem);
  const usedItems = items.slice(0, 5);

  return (
    <div className={twMerge('my-4', className)}>
      <div className="hidden md:block">
        <CorporateTabsDesktop
          buttonClassName={buttonClassName}
          currentTab={currentTab}
          items={usedItems}
          navigate={setCurrentTab}
          variant={variant}
        />
        <CorporateTabsContentDesktop
          className={contentClassName}
          currentTab={currentTab}
          items={usedItems}
          variant={variant}
        />
      </div>
      <CorporateTabsMobile
        buttonClassName={buttonClassName}
        className="visible md:hidden"
        currentTab={currentTab}
        items={usedItems}
        navigate={setCurrentTab}
        variant={variant}
      />
    </div>
  );
};

export default CorporateTabs;
