import { useTranslation } from 'next-i18next';

import { useIsSSR } from '@boss/hooks';
import { ColorButton } from '@boss/ui';

import { useColorByColorId } from '../../../client-queries';
import { useFavorites } from '../../../hooks';

type Props = {
  id: string;
};

const CfColorButton = ({ id }: Props) => {
  const { data: color, isLoading } = useColorByColorId(id);
  const { favorites, toggleFavorite } = useFavorites();
  const { t } = useTranslation();
  const isSSR = useIsSSR();

  if (isSSR) {
    return null;
  }

  return (
    <ColorButton
      {...color}
      backgroundColor={color?.rgb ?? ''}
      id={color?.code}
      isFavorite={favorites?.COLORS?.includes(color?.code ?? '')}
      loading={isLoading}
      onFavorite={() => toggleFavorite('COLORS', color?.code ?? '', color)}
      translations={{ trending: t('trending') ?? '' }}
      variant="secondary"
    />
  );
};

export default CfColorButton;
