import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';

import { trackEvents } from '@boss/constants/shared';
import { IWishlist } from '@boss/services/client';
import { ISearchProduct } from '@boss/types/b2b-b2c';
import { Alert, Button } from '@boss/ui';
import { getAccountPageSlugs } from '@boss/utils';

import { useProductsById } from '../../../../client-queries';
import { ListNameEdit, PagedProducts } from '../../../../components';
import { useEventTracker, useWishlistOperations } from '../../../../hooks';
import { accountPageConfig, isB2b } from '../../../../utils';
import ProductSearch from '../search/products';

interface Props {
  list: IWishlist;
  readonly?: boolean;
}

const ProductListDetail = ({ list, readonly }: Props) => {
  const {
    removeFromList,
    createWishlistLine,
    updateListName,
    listName,
    setListName,
    editMode,
    setEditMode,
    showError,
  } = useWishlistOperations(list);
  const [listViewTracked, setListViewTracked] = useState(false);

  const { trackCustomEvent } = useEventTracker();

  const { listPageTypeToShow } = accountPageConfig;
  const listLineIds = list.lines.filter(line => line.typeid).map(line => line.typeid ?? '');

  const { data: productResults } = useProductsById(listLineIds);
  const { t } = useTranslation('account', { keyPrefix: 'list.detailPage' });
  const { locale } = useRouter();

  const addProductToList = async (product: ISearchProduct) => {
    createWishlistLine({
      wishlistId: list.id ?? '',
      wishlistLine: {
        typeid: product.id,
      },
      wishlistType: list.type,
    });
  };

  const _removeFromList = (productId: string) => {
    const wishlistLineId = list.lines.find(line => line.typeid === productId)?.id;

    removeFromList(wishlistLineId ?? '');
  };

  useEffect(() => {
    if (productResults && !listViewTracked) {
      trackCustomEvent(trackEvents.VIEW_LIST, {
        item_list_name: listName,
        items: productResults?.map((product, index) => ({
          item_id: product.id,
          item_name: product.name,
          item_category: product.category ?? product.categories?.[0],
          index,
        })),
      });
      setListViewTracked(true);
    }
  }, [productResults, listViewTracked, listName, trackCustomEvent]);

  return (
    <div className="mb-10 flex flex-col gap-3">
      {!readonly ? (
        <>
          <ListNameEdit
            editMode={editMode}
            listDescription={list.description ?? ''}
            listName={listName}
            setEditMode={setEditMode}
            setListName={setListName}
            updateListName={updateListName}
          />
          {showError && (
            <Alert className="mt-2" type="error">
              {t('updateError')}
            </Alert>
          )}
          {isB2b && <ProductSearch addProductToList={product => addProductToList(product)} />}
        </>
      ) : (
        <h1>{listName}</h1>
      )}
      {!list.lines.length ? (
        <p className="mt-5">{t('emptyList')}</p>
      ) : (
        <PagedProducts className="mt-5" onRemove={product => _removeFromList(product.id)} products={productResults} />
      )}
      <Button
        className="ml-auto mt-5"
        href={`${getAccountPageSlugs(listPageTypeToShow)[locale as string]}`}
        label={t('backToOverview')}
        type="primary"
      />
    </div>
  );
};

export default ProductListDetail;
