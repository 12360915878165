import { useEffect, useState } from 'react';

import { trackEvents } from '@boss/constants/shared';
import { IProduct } from '@boss/services';
import { ISearchProduct } from '@boss/types/b2b-b2c';
import { CarouselProducts } from '@boss/ui';

import { useEventTracker } from '../../hooks';

type Props = Parameters<typeof CarouselProducts>[0] & {
  title: string;
  products: ISearchProduct[] | IProduct[];
};

export const CarouselProductsWrapper = ({ title, products, ...props }: Props) => {
  const { trackCustomEvent } = useEventTracker();
  const [carouselViewTracked, setCarouselViewTracked] = useState(false);

  useEffect(() => {
    if (products && !carouselViewTracked) {
      trackCustomEvent(trackEvents.VIEW_LIST, {
        item_list_name: title,
        items: products.map((product, index) => ({
          item_id: product.id,
          item_name: product.name,
          item_category: (product as ISearchProduct).category ?? (product as IProduct).categories?.[0]?.name,
          index,
          item_list_name: title,
        })),
      });
      setCarouselViewTracked(true);
    }
  }, [products, title, trackCustomEvent, carouselViewTracked]);

  return <CarouselProducts {...props} title={title} />;
};

export default CarouselProductsWrapper;
