import React from 'react';
import { twMerge } from 'tailwind-merge';

import { CorporateTabItem } from '..';
import CorporateTab from '../CorporateTab';

interface CorporateTabsMobileProps {
  items: CorporateTabItem[];
  currentTab?: number;
  navigate: (index: number) => void;
  className?: string;
  buttonClassName?: string;
  variant?: 'primary' | 'secondary';
}

const CorporateTabsMobile: React.FC<CorporateTabsMobileProps> = ({
  items,
  currentTab,
  navigate,
  className,
  buttonClassName,
  variant = 'primary',
}) => {
  return (
    <div className={twMerge('grid grid-cols-1 gap-4 py-4', className)}>
      {items.map((item: CorporateTabItem, index: number) => {
        return (
          <div className="w-full" key={`dropdown-${index}`}>
            <CorporateTab
              buttonClassName={buttonClassName}
              index={index}
              isActive={index === currentTab}
              item={item}
              navigate={navigate}
              textClassName="text-white"
              variant={variant}
            />
            {currentTab === index && <div className="pb-2 pt-6">{item.content}</div>}
          </div>
        );
      })}
    </div>
  );
};

export default CorporateTabsMobile;
