/* eslint-disable sonarjs/cognitive-complexity */
import { useRouter } from 'next/router';

import { CHANNEL } from '@boss/constants/b2b-b2c';
import { trackEvents } from '@boss/constants/shared';
import { Tracker } from '@boss/utils';

import { useProfile } from '../';

export const useEventTracker = () => {
  const { accountId, isLoggedIn } = useProfile();
  const { locale } = useRouter();

  const commonFields = {
    language: locale,
    visitorLoginStatus: isLoggedIn ? 'logged-in' : 'logged-out',
    userId: accountId,
    brand: CHANNEL ?? '',
  };

  const ECOMMERCE_EVENTS = [
    trackEvents.SELECT_ITEM,
    trackEvents.VIEW_ITEM,
    trackEvents.ADD_TO_CART,
    trackEvents.BEGIN_CHECKOUT,
    trackEvents.VIEW_LIST,
    trackEvents.ADD_TO_CART,
  ] as const;

  const formatEcommerceItem = (props: Record<string, unknown>, event: string) => {
    const baseItem = {
      item_id: props.item_id || props.productId,
      item_id_detail: props.item_id_detail || props.articleId,
      item_name_detail: props.item_name_detail || props.articleName,
      item_name: props.item_name || props.productName,
      affiliation: `${locale}-${CHANNEL} webshop`,
      index: props.index || 0,
      item_brand: commonFields.brand,
      item_category: props.item_category || props.productCategory,
      item_category2: props.item_category2 || '',
      item_category3: props.item_category3 || '',
      item_category4: props.item_category4 || '',
      item_category5: props.item_category5 || '',
      item_list_id: props.item_list_id || '',
      item_list_name: props.item_list_name || '',
      price: props.price || 0,
    };

    if (event === trackEvents.ADD_TO_CART) {
      return {
        ...baseItem,
        item_id_detail: props.item_id_detail || props.articleId,
        item_name_detail: props.item_name_detail || props.articleName,
        price: props.price || 0,
        quantity: props.quantity || 1,
      };
    }

    return baseItem;
  };

  const trackEcommerceEvent = (event: string, props: Record<string, unknown>) => {
    // Clear the previous ecommerce object
    window.dataLayer?.push({ ecommerce: null });

    const items = Array.isArray(props.items)
      ? props.items.map(item => formatEcommerceItem(item, event))
      : [formatEcommerceItem(props, event)];

    const totalValue = event === trackEvents.ADD_TO_CART ? props.totalPrice || props.value || 0 : 0;

    window.dataLayer?.push({
      event,
      ecommerce: {
        ...(event === trackEvents.SELECT_ITEM && {
          item_list_id: '',
          item_list_name: props.item_list_name,
        }),
        ...(event === trackEvents.ADD_TO_CART && {
          currency: 'EUR',
          value: totalValue,
        }),
        items,
      },
      ...commonFields,
    });
  };

  const tracker = new Tracker(commonFields);

  const trackCustomEvent = (event: string, props: Record<string, unknown>) => {
    if (ECOMMERCE_EVENTS.includes(event)) {
      trackEcommerceEvent(event, props);
    } else {
      tracker.trackCustomEvent({
        event,
        ...props,
      });
    }
  };

  const trackPageView = (pageType: string) =>
    tracker.trackPageView({
      pageType,
    });

  const trackLinkClick = (props: Record<string, unknown>) =>
    tracker.trackLinkClick({
      ...props,
    });

  return {
    trackPageView,
    trackLinkClick,
    trackCustomEvent,
  };
};
