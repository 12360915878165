import { useTranslation } from 'next-i18next';

import { termsAndConditionsPageSlugs } from '@boss/constants/b2b-b2c';
import { useRouter } from '@boss/hooks';
import { Link } from '@boss/ui';

type Props = {
  className?: string;
  href?: string;
};

const TermsAndConditionsLink = ({ className, href }: Props) => {
  const { locale } = useRouter();
  const { t } = useTranslation('forms');

  const translations: Record<string, string> = t('checkboxes.termsandconditions', { returnObjects: true }) ?? {};

  return (
    <span className={className}>
      {translations['1'] ?? ''}{' '}
      <Link className="text-linkColor underline" href={`/${href ?? termsAndConditionsPageSlugs[locale]}`} newTab>
        {translations['2'] ?? ''}
      </Link>{' '}
      {translations['3'] ?? ''}
    </span>
  );
};

export default TermsAndConditionsLink;
