import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useState } from 'react';

import { workshopCheckoutPageSlugs } from '@boss/constants/b2b-b2c';

import { useParticipateEvent } from '../../client-queries';
import { ORDER_ID_PARAMETER, STORE_SERVICE_ID_PARAMETER, WORKSHOP_ID_PARAMETER } from '../../constants/checkout';
import { useFormField } from '../../hooks';
import DynamicForm, { FormValues } from '../DynamicForm';
import { FormField } from '../Mappers/FormFieldMapper';

type Props = {
  id?: string;
  eventId: string;
  locationId: string;
  skuId: string;
  storeServiceId: string;
  isFree: boolean;
};

const WorkshopForm = ({ id = 'workshop-form', eventId, locationId, skuId, storeServiceId, isFree }: Props) => {
  const { t } = useTranslation('forms');
  const { push, locale } = useRouter();
  const { firstname, lastname, email, phonenumber, question, workshoptermsandconditions, newsletter } = useFormField();
  const [participants, setParticipants] = useState(1);

  const errorToast = {
    title: t('errors.submitTitle'),
    description: t('errors.submitSubtitle'),
  };
  const {
    mutateAsync: submitForm,
    isLoading: isSubmitting,
    isSuccess,
    reset,
  } = useParticipateEvent({
    toasts: { error: errorToast },
  });

  const handleSubmit = async (vals: FormValues) => {
    const response = await submitForm({
      eventId,
      formData: {
        firstname: vals.firstname,
        lastname: vals.lastname,
        email: vals.email,
        phonenumber: vals.phonenumber,
        comment: vals.message,
        onlinecommunication: vals.newsletter,
        quantity: 1, // For now we only support 1 participant per order
        locationid: locationId,
        skuid: skuId,
      },
    });

    if (response?.basketid) {
      push(
        `/${workshopCheckoutPageSlugs[locale as string]}?${ORDER_ID_PARAMETER}=${
          response.basketid
        }&${WORKSHOP_ID_PARAMETER}=${eventId}&${STORE_SERVICE_ID_PARAMETER}=${storeServiceId}`,
      );
    }
  };

  const updateFormValues = (vals: FormValues) => {
    const newParticipants = Number(vals.participants ?? 1);

    if (participants !== newParticipants) {
      setParticipants(newParticipants);
    }
  };

  const shouldShowField = (field: string) => {
    if (field === 'message') {
      return participants < 2;
    }

    return true;
  };

  const fieldClassName = 'col-span-6 md:col-span-3';

  const userInfoFields: FormField[] = [
    { ...firstname, colStyle: fieldClassName },
    { ...lastname, colStyle: fieldClassName },
    { ...email, colStyle: fieldClassName },
    { ...phonenumber, colStyle: fieldClassName },
    {
      ...question,
      required: false,
      label: t('fields.questionWorkshop'),
      colStyle: 'col-span-6',
    },
  ];

  const checkboxFields: FormField[] = [workshoptermsandconditions, newsletter];

  return (
    <>
      <DynamicForm
        buttonProps={{ label: t('buttons.subscribe') ?? '' }}
        columns={[
          { id: `${id}-user-info`, fields: userInfoFields },
          { id: `${id}-checkboxes`, fields: checkboxFields, formFieldsWrapperClassName: 'flex flex-col gap-4' },
        ]}
        columnsWrapperClassName="grid gap-6 lg:gap-x-30 md:grid-cols-2"
        eventId={eventId}
        id={id}
        isSubmitting={isSubmitting}
        isSuccess={isSuccess}
        onCloseAlert={reset}
        onFormValuesChange={e => updateFormValues(e.values)}
        onSubmit={handleSubmit}
        shouldShowField={shouldShowField}
        variant="light"
      />
      {!isFree && (
        <div className="-mt-2 text-right text-sm italic text-gray-500">{t('disclaimers.workshopdisclaimer')}</div>
      )}
    </>
  );
};

export default WorkshopForm;
