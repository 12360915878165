/* eslint-disable import/order */
import React from 'react';
import { twMerge } from 'tailwind-merge';
import { cva } from 'class-variance-authority';

import { CorporateTabItem } from '..';

interface CorporateTabsContentDesktopProps {
  items: CorporateTabItem[];
  currentTab: number;
  className?: string;
  variant?: 'primary' | 'secondary';
}

const ItemStyling = cva('', {
  variants: {
    active: {
      true: 'visible',
      false: 'hidden',
    },
    variant: {
      primary: '',
      secondary: 'border-l-4 pl-4 md:w-1/2',
    },
  },
});

const CorporateTabsContentDesktop: React.FC<CorporateTabsContentDesktopProps> = ({
  items,
  currentTab,
  className,
  variant = 'primary',
}) => {
  return (
    <div className={twMerge('mt-4', className)}>
      {items.map((item: CorporateTabItem, index: number) => (
        <div
          className={ItemStyling({ active: index === currentTab, variant })}
          key={`content-${index}`}
          style={variant === 'secondary' ? { borderLeftColor: item.color } : undefined}
        >
          {item.content}
        </div>
      ))}
    </div>
  );
};

export default CorporateTabsContentDesktop;
