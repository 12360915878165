import React from 'react';

import { CorporateTabItem } from '..';
import CorporateTab from '../CorporateTab';

interface CorporateTabsDesktopProps {
  items: CorporateTabItem[];
  currentTab?: number;
  navigate: (index: number) => void;
  buttonClassName?: string;
  variant?: 'primary' | 'secondary';
}

const CorporateTabsDesktop: React.FC<CorporateTabsDesktopProps> = ({
  items,
  currentTab,
  navigate,
  buttonClassName,
  variant = 'primary',
}) => {
  return (
    <div className="grid grid-flow-col grid-cols-1 gap-3 py-4 text-white md:auto-cols-fr lg:gap-4">
      {items.map((item: CorporateTabItem, index: number) => {
        return (
          <CorporateTab
            buttonClassName={buttonClassName}
            index={index}
            isActive={index === currentTab}
            item={item}
            key={`button-${index}`}
            navigate={navigate}
            variant={variant}
          />
        );
      })}
    </div>
  );
};

export default CorporateTabsDesktop;
